import React, { useState, useEffect } from "react"
import css from "@styled-system/css"
import dayjs from "dayjs"
import { useStaticQuery, graphql } from "gatsby"

import {
  Flex,
  SEO,
  UpdateCard,
  PageHeader,
  Button,
  SimpleSelect,
  Wrapper,
} from "components"
import { usePreviewData } from "utils"

function NewsPage({ location }) {
  const data = useStaticQuery(
    graphql`
      {
        prismicUpdates {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
          }
        }
        updates: allPrismicPost(sort: { order: DESC, fields: data___date }) {
          nodes {
            uid
            data {
              title {
                text
              }
              community {
                uid
              }
              tag
              date
              excerpt
              body {
                ... on PrismicPostBodyCallToAction {
                  id
                }
                ... on PrismicPostBodyImage {
                  id
                }
                ... on PrismicPostBodyText {
                  id
                }
              }
              image {
                url
                alt

                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              featured
            }
          }
        }
        communities: allPrismicCommunity {
          nodes {
            prismicId
            uid
            data {
              project_status
              name {
                text
              }
            }
          }
        }
      }
    `
  )

  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicUpdates, communities, updates } = MERGED_DATA

  //filter items
  const pageSize = 20
  const [maxItems, setMaxItems] = useState(pageSize)
  const [filter, setFilter] = useState("")
  const [community, setCommunity] = useState("")

  const loadMore = () => setMaxItems(maxItems + pageSize)

  useEffect(() => {
    if (location.search.indexOf("?filter=") !== -1) {
      setFilter(location.search.replace("?filter=", ""))
    }
    if (location.search.indexOf("?community=") !== -1) {
      setCommunity(location.search.replace("?community=", ""))
    }
  }, [])
  useEffect(() => setMaxItems(pageSize), [filter])

  return (
    <>
      <SEO
        title={prismicUpdates.data.seo_title}
        description={prismicUpdates.data.seo_description}
        image={prismicUpdates.data.seo_image}
      />
      <PageHeader
        image={prismicUpdates.data.hero_image}
        video={prismicUpdates.data.hero_video}
        title={prismicUpdates.data.hero_title}
        subtitle={prismicUpdates.data.hero_subtitle}
      />
      <Wrapper pt={[3, 4]} mb={[4, 5]}>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          css={css({
            m: "-2px",
            "> *": {
              m: "2px",
            },
          })}
        >
          <SimpleSelect
            value={filter}
            onChange={event => {
              setFilter(event.target.value)
            }}
          >
            <option children={`All categories`} value="" />
            <option children={`Corporate`} value="Corporate" />
            <option children={`Lifestyle`} value="Lifestyle" />
          </SimpleSelect>
          <SimpleSelect
            value={community}
            onChange={event => {
              setCommunity(event.target.value)
            }}
          >
            <option children={`All communities`} value="" />
            {communities.nodes.map((community, index) => {
              if (
                community.data.project_status !== "Past" &&
                community.data.project_status !== "Hidden"
              ) {
                return (
                  <option
                    children={community.data.name && community.data.name.text}
                    value={community.uid && community.uid}
                  />
                )
              }
            })}
          </SimpleSelect>
        </Flex>
      </Wrapper>
      <Wrapper>
        <Flex
          flexWrap="wrap"
          css={css({
            mx: [-2, -3, null, -4],
            my: -3,
            "> *": {
              width: ["100%", "50%", "33.333%"],
              px: [2, 3, null, 4],
              py: 3,
            },
          })}
        >
          {updates.nodes &&
            updates.nodes
              .filter(update => {
                if (
                  (!filter && !community) ||
                  (filter && filter === update.data.tag) ||
                  (update.data.community &&
                    community === update.data.community.uid)
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map((update, index) => {
                if (update.data.body && update.data.body[0]) {
                  return (
                    <UpdateCard
                      useLink={update.data.body && update.data.body[0]}
                      tag={update.data.tag}
                      to={"/updates/" + update.uid + "/"}
                      image={update.data.image}
                      heading={update.data.title && update.data.title.text}
                      key={"updates" + update.uid + index}
                      excerpt={update.data.excerpt}
                      date={dayjs(update.data.date).format("MMMM D, YYYY")}
                    />
                  )
                } else {
                  return null
                }
              })}
        </Flex>
      </Wrapper>
      {updates.nodes.filter(update => {
        if (
          (!filter && !community) ||
          (filter && filter === update.data.tag) ||
          (update.data.community && community === update.data.community.uid)
        ) {
          return true
        } else {
          return false
        }
      }).length > maxItems && (
        <Wrapper mt={5} pt={4} borderTop="1px solid" borderColor="border">
          <Flex justifyContent="center" bg="background">
            <Button
              children={`Load more`}
              variant="default"
              onClick={loadMore}
            />
          </Flex>
        </Wrapper>
      )}
    </>
  )
}

export default NewsPage
